<template>
  <form id="newRecord" @submit.prevent="saveOrUpdate">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="mle" class="form-label">Marco Legal específico:</label>
      <input
        class="form-control"
        id="mle"
        disabled
        :value="marcoLegalEspecifico.name"
      />
    </div>
    <div class="mb-3">
      <label for="aspecto" class="form-label">Categoría:</label>
      <select
        id="aspecto"
        class="form-select"
        required
        v-model="tool.category_id"
      >
        <option
          v-for="category in categories"
          :value="category.id"
          :key="category.id"
        >
          {{ category.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input v-model="tool.name" class="form-control" id="name" required />
    </div>
    <div class="mb-3">
      <label for="description" class="form-label">Descripción:</label>
      <textarea
        v-model="tool.description"
        class="form-control"
        id="description"
        required
        rows="3"
      />
    </div>
    <div class="mb-3">
      <label for="content" class="form-label">Contenido:</label>
      <textarea
        rows="4"
        v-model="tool.content"
        class="form-control"
        id="content"
        required
      />
    </div>
    <div class="row">
      <div class="col-md-3">
        <!-- <div class="mb-3">
          <label for="price" class="form-label">Precio:</label>
          <input
            v-model="tool.price"
            class="form-control"
            id="price"
            type="number"
            min="0"
            step=".01"
            required
          />
        </div>-->
        <div class="mb-3">
          <label for="applicationDate" class="form-label"
            >Herramienta gratuita:</label
          >
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="radioFreeTool"
                id="radioYes"
                :value="true"
                v-model="tool.free"
              />
              <label class="form-check-label" for="radioYes">Sí</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="radioFreeTool"
                id="radioNo"
                :value="false"
                v-model="tool.free"
              />
              <label class="form-check-label" for="radioNo">No</label>
            </div>
          </div>
          <!-- <input
            v-if="publicacionEnabledDate"
            v-model="dof.fecha_entrada_en_vigor"
            type="date"
            class="form-control mt-2"
            id="applicationDate"
            required
          />-->
        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <label for="inventary_id" class="form-label">ID inventario:</label>
          <input
            v-model="tool.inventary_id"
            class="form-control"
            id="inventary_id"
            required
          />
        </div>
      </div>
      <div v-if="!tool.free" class="mb-3">
        <label for="link" class="form-label">link:</label>
        <input v-model="tool.link" class="form-control" id="link" required />
      </div>
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/tools";
import { all as allCategories } from "@/services/categories";
import { find as findMarcoLegalEspecifico } from "@/services/marcos_legales_especificos";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CPrimaryButton,
    C422Errors,
  },
  mounted() {
    this.getCategories();
    this.getTool();
    this.getMarcoLegalEspecífico();
  },
  data() {
    return {
      marcoLegalEspecifico: { name: "" },
      categories: [],
      toolId: this.$route.params.toolId,
      tool: {
        marco_legal_especifico_id: this.$route.params.mleId,
        category_id: "",
        inventary_id: "",
        free: false,
        content: "",
        description: "",
        name: "",
        link: null,
      },
      errors: {},
      loading: false,
    };
  },
  methods: {
    getMarcoLegalEspecífico() {
      findMarcoLegalEspecifico(this.tool.marco_legal_especifico_id).then(
        (response) => {
          this.marcoLegalEspecifico = response.data;
        }
      );
    },
    getTool() {
      if (!this.toolId) {
        return;
      }
      find(this.toolId).then((response) => {
        this.tool = response.data;
      });
    },
    getCategories() {
      allCategories()
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las categorias");
        });
    },
    saveOrUpdate() {
      this.tool.link = !this.tool.free ? this.tool.link : null;
      if (this.toolId) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      create(this.tool).then(() => {
        showNotification(201);
        this.$router.push(
          `/admin/marcos-legales-generales/${this.$route.params.id}/versiones/${this.$route.params.versionId}/marcos-legales-especificos/${this.$route.params.mleId}/herramientas`
        );
      });
    },
    update() {
      update(this.toolId, this.tool).then(() => {
        showNotification(
          200,
          "success",
          "Se actualizó la herramienta de forma correcta."
        );
        this.$router.push(
          `/admin/marcos-legales-generales/${this.$route.params.id}/versiones/${this.$route.params.versionId}/marcos-legales-especificos/${this.$route.params.mleId}/herramientas`
        );
      });
    },
  },
};
</script>
